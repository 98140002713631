<template>
  <!-- 优惠券列表 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="glo_btnList" ref="btnList">
      <div class="glo_btnList_left">
        <el-button class="glo_btnItems glo_bgcMainColor" @click="AddBtns()"
          >新建</el-button
        >
        <el-button class="glo_btnItems" @click="delCouponBtns()"
          >批量删除</el-button
        >
        <el-button class="glo_btnItems" @click="upCouponBtns()"
          >批量上架</el-button
        >
        <el-button class="glo_btnItems" @click="downCouponBtns()"
          >批量下架</el-button
        >

        <!-- <el-dropdown class="topDropDown" @command="topCommandBtns">
          <el-button type="primary">
            更多操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dtdc">导出</el-dropdown-item>
            <el-dropdown-item command="bgsz">表格设置</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
      <queryBtns
        :MoreConditions="MoreConditions"
        @clerBtns="clerBtns"
        @queryBtn_ok="queryBtn_ok"
        @moreParam="moreParam"
      ></queryBtns>
    </div>
    <!-- 查询条件 -->
    <!-- :formData="queryData" -->
    <queryCondition
      ref="queryForm"
      :More="isHaseMore"
      :formConfig="queryCondditionDatas"
    ></queryCondition>
    <!-- 表格 -->
    <div class="glo_Table">
      <el-table
        :height="tableHeigth"
        header-align="center"
        ref="mytable"
        @row-click="rowclick"
        :data="tableData"
        border
        stripe
        :highlight-current-row="true"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          align="center"
          label="序号"
          type="index"
          width="60"
        ></el-table-column>
        <el-table-column
          fixed="left"
          align="center"
          type="selection"
          width="60"
        ></el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <!-- 某个字段需要单独操作  例如点击可以跳转 -->
          <el-table-column
            v-if="item.field == 'aaaaaaaaaa' && item.isShow"
            :key="index"
            :label="item.name"
            :prop="item.field"
            align="left"
            sortable
            :show-overflow-tooltip="true"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
          >
            <template slot-scope="scope">
              <div class="linkTabel">
                {{ scope.row.aaaaaaaaaa }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            align="center"
            sortable
            :show-overflow-tooltip="true"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-else-if="item && item.isShow"
          ></el-table-column>
        </template>
        <el-table-column fixed="right" label="操作" min-width="180px">
          <template slot-scope="scope">
            <div class="tabRightBtns">
              <el-button class="rbtns" @click.stop="ModifyBtns(scope.row)"
                >修改</el-button
              >
              <el-button class="rbtns" @click.stop="sendCouponBtns(scope.row)"
                >指定发放</el-button
              >
              <el-button class="rbtns" @click.stop="delCouponBtns(scope.row)"
                >删除</el-button
              >
              <!-- 如果按钮超过4个就要用下面这种下拉的样式 -->
              <!-- <el-dropdown
                  class="rbtns"
                  @command="handleCommandBtns($event, scope.row)"
                >
                  <el-button type="primary">
                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="a">详情</el-dropdown-item>
                    <el-dropdown-item command="b">外发录入</el-dropdown-item>
                    <el-dropdown-item command="c">改运单号</el-dropdown-item>
                    <el-dropdown-item command="d">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <paging
      ref="pags"
      :pageNum="pageStart"
      :total="pageCount"
      :size="pageTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>
    <!--        自定义表头           -->
    <!-- <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable> -->

    <!-- 导出插件 -->
    <!-- <exportCompoent
      :isShowDialog_dc="isShowDialog_dc"
      :diaWidth_dc="diaWidth_dc"
      :dialogTitle_dc="dialogTitle_dc"
      :dialogForm_dc="dialogForm_dc"
      :tableHeard="EPtableHeard"
      :AllCheck="EPAllCheck"
      :queryCondditionDatas="queryCondditionDatas"
      @checktabEP="checktabEP"
      @checkAllEP="checkAllEP"
      @onAfterEnter="onAfterEnter"
      @handleCloseDia_dc="handleCloseDia_dc"
      @diaConfirmBtn_dc="diaConfirmBtn_dc()"
    >
    </exportCompoent> -->

    <!-- 新建/修改优惠券弹窗 -->
    <myDialog
      :diaWidth="diaWidth2"
      :dialogTitle="dialogTitle2"
      :isShowDialog="isShowDialog2"
      @handleCloseDia="handleCloseDia2"
      @diaCancelBtn="handleCloseDia2"
      @diaConfirmBtn="diaConfirmBtn2('dialogFormRef2')"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="isShowDialog2"
          ref="dialogFormRef2"
          :model="dialogForm2"
          label-width="112px"
          :rules="rulesForm2"
        >
          <el-form-item class="dialogFormItems" label="优惠券编号:">
            <el-input
              disabled
              v-model="dialogForm2.number"
              placeholder="自动生成"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="couponName"
            label="优惠券名称:"
          >
            <el-input
              placeholder="请输入"
              v-model.trim="dialogForm2.couponName"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="useOrientation"
            label="使用方向:"
          >
            <el-select
              filterable
              clearable
              v-model.trim="dialogForm2.useOrientation"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, indexA) in downListA"
                :key="indexA"
                :label="item.stateShow"
                :value="item.state"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            class="dialogFormItems"
            prop="totalCount"
            label="优惠券数量:"
          >
            <el-input
              type="number"
              min="0"
              placeholder="请输入"
              v-model.trim="dialogForm2.totalCount"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="getCount"
            label="已领取数量:"
          >
            <el-input
              type="number"
              min="0"
              placeholder="请输入"
              v-model.trim="dialogForm2.getCount"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="remainingCount"
            label="剩余数量:"
          >
            <el-input
              type="number"
              min="0"
              placeholder="请输入"
              v-model.trim="dialogForm2.remainingCount"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="getMethod"
            label="领取方式:"
          >
            <el-select
              filterable
              clearable
              v-model="dialogForm2.getMethod"
              placeholder="请选择"
              @change="changeGetMethod"
            >
              <el-option
                v-for="(item, indexA) in downListD"
                :key="indexA"
                :label="item.stateShow"
                :value="item.state"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="couponGetConditionId"
            label="领取条件:"
          >
            <el-select
              filterable
              clearable
              v-model.trim="dialogForm2.couponGetConditionId"
              placeholder="请选择"
              @visible-change="getDown_list_coupon_condition"
            >
              <el-option
                v-for="(item, indexA) in downListC"
                :key="indexA"
                :label="item.conditionName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            prop="couponStatus"
            label="优惠券状态:"
          >
            <el-select
              filterable
              clearable
              v-model.trim="dialogForm2.couponStatus"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, indexA) in downListG"
                :key="indexA"
                :label="item.stateShow"
                :value="item.state"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="rows">
            <el-form-item
              class="dialogFormItems"
              prop="couponType"
              label="优惠券类型:"
            >
              <el-select
                filterable
                clearable
                v-model.trim="dialogForm2.couponType"
                placeholder="请选择"
                @change="changeCouponType"
                @visible-change="getDown_list_coupon_type"
              >
                <el-option
                  v-for="(item, indexA) in downListB"
                  :key="indexA"
                  :label="item.stateShow"
                  :value="item.state"
                ></el-option>
              </el-select>
            </el-form-item>

            <div class="couponBox" v-if="dialogForm2.couponType">
              <!-- 现金券 -->
              <div
                class="couponItemA"
                v-if="dialogForm2.couponType == 'xian_jin:coupon_type'"
              >
                <div>优惠金额</div>
                <el-input
                  class="couponitmeInput"
                  type="number"
                  min="0"
                  placeholder="请输入"
                  v-model.trim="dialogForm2.discountAmount"
                  @input="inputNumbeA"
                ></el-input>
                <div>元 备注: 现金券满0.01元即可使用</div>
              </div>

              <!-- 满减券 -->
              <div
                class="couponItemA"
                v-if="dialogForm2.couponType == 'man_jian:coupon_type'"
              >
                <div>满</div>
                <el-input
                  class="couponitmeInput"
                  type="number"
                  min="0"
                  placeholder="请输入"
                  v-model.trim="dialogForm2.fullCondition"
                  @input="inputNumbeB"
                ></el-input>
                <div style="margin-right: 10px">元,</div>

                <div>减</div>
                <el-input
                  class="couponitmeInput"
                  type="number"
                  placeholder="请输入"
                  v-model.trim="dialogForm2.subAmount"
                  @input="inputNumbeC"
                ></el-input>
                <div>元</div>
              </div>

              <!-- 满折券 -->
              <div
                class="couponItemA"
                v-if="dialogForm2.couponType == 'man_zhe:coupon_type'"
              >
                <div>满</div>
                <el-input
                  class="couponitmeInput"
                  type="number"
                  placeholder="请输入"
                  v-model.trim="dialogForm2.fullCondition"
                  @input="inputNumbeB"
                ></el-input>
                <div style="margin-right: 10px">元,</div>

                <div>打</div>
                <el-input
                  class="couponitmeInput"
                  type="number"
                  placeholder="请输入"
                  v-model.trim="dialogForm2.discount"
                  @input="inputNumbeD"
                ></el-input>
                <div>折</div>
              </div>
            </div>
          </div>

          <div class="rows">
            <el-form-item
              class="dialogFormItems"
              prop="validTimeType"
              label="使用时间类型:"
            >
              <el-select
                filterable
                clearable
                v-model="dialogForm2.validTimeType"
                placeholder="请选择"
                @change="changeValidTimeType"
              >
                <el-option
                  v-for="(item, indexA) in downListF"
                  :key="indexA"
                  :label="item.stateShow"
                  :value="item.state"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="dialogFormItems"
              prop="invalidTime"
              label="失效日期:"
              v-if="dialogForm2.validTimeType === 'jue_dui:valid_type'"
            >
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model.trim="dialogForm2.invalidTime"
                type="datetime"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              class="dialogFormItems"
              prop="lengthDay"
              label="相对时间:"
              v-if="dialogForm2.validTimeType === 'xiang_dui:valid_type'"
            >
              <el-input
                placeholder="请输入"
                v-model.trim="dialogForm2.lengthDay"
              >
                <template #append>天</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="rows">
            <el-form-item
              class="dialogFormItems"
              prop="getTimeType"
              label="领取时间类型:"
            >
              <el-select
                filterable
                clearable
                v-model.trim="dialogForm2.getTimeType"
                placeholder="请选择"
                @change="changeGetTimeType"
              >
                <el-option
                  v-for="(item, indexA) in downListE"
                  :key="indexA"
                  :label="item.stateShow"
                  :value="item.state"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="dialogFormItems"
              label="可领取时间:"
              style="width: 63%"
              v-if="dialogForm2.getTimeType == 'duan_qi:get_time_type'"
            >
              <div class="displays">
                <el-date-picker
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model.trim="dialogForm2.getStartTime"
                  type="datetime"
                  placeholder="选择日期"
                >
                </el-date-picker>
                <div>至</div>
                <el-date-picker
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model.trim="dialogForm2.getEndTime"
                  type="datetime"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </div>
          <div class="classA" style="width: 100%">
            <div style="width: 80px">适用范围:</div>
            <el-input
              type="textarea"
              placeholder="请输入"
              v-model.trim="dialogForm2.remark"
            ></el-input>
          </div>
        </el-form>
      </div>
    </myDialog>

    <!-- 新建/修改优惠券弹窗 -->
    <myDialog
      diaWidth="400px"
      dialogTitle="发放优惠券"
      :isShowDialog="isShowDialog3"
      @handleCloseDia="handleCloseDia3"
      @diaCancelBtn="handleCloseDia3"
      @diaConfirmBtn="diaConfirmBtn3('dialogFormRef2')"
    >
      <div class="glo_dialogForm multipleSelect">
        <el-form
          class="diaForm"
          v-if="isShowDialog3"
          ref="dialogFormRef2"
          :model="dialogForm3"
          label-width="112px"
        >
          <el-form-item label="接收人:">
            <el-select
              filterable
              clearable
              v-model="dialogForm3.userId"
              placeholder="请选择"
              remote
              :remote-method="getUserData"
              @change="changeUserList"
            >
              <el-option
                v-for="(item, indexA) in userList"
                :key="indexA"
                :label="item.userName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>
  <script>
import tableList from "@/pages/DynamicList/tableList.js";
//   import sorttable from "@/components/sortTemplate.vue"; //自定义排序表头
import paging from "@/components/pagings.vue"; //分页组件
import queryBtns from "@/components/queryBtnsDT.vue";
import queryCondition from "@/components/queryCondition.vue";
import myDialog from "@/components/DialogDT.vue";

//   import exportjs from "@/assets/js/Mixins/exportDT.js"; //导出的公共js

export default {
  mixins: [tableList],
  inject: ["reload"],
  components: {
    //   sorttable,
    paging,
    queryBtns,
    queryCondition,
    myDialog,
  },
  data() {
    return {
      userList: [],
      downListA: [
        // 使用放向
        {
          stateShow: "通用券",
          state: "tong_yong:use_orientation",
        },
        {
          stateShow: "运费券",
          state: "yun_fei:use_orientation",
        },
        {
          stateShow: "购物券",
          state: "gou_wu:use_orientation",
        },
      ],
      downListB: [], //
      downListC: [], //领取条件
      downListD: [
        //领取方式
        {
          stateShow: "主动领取",
          state: "zhu_dong_ling_qu:get_method",
        },
        {
          stateShow: "自动放发",
          state: "zi_dong_fa_fang:get_method",
        },
      ],
      downListE: [
        //领取时间类型
        {
          stateShow: "短期",
          state: "duan_qi:get_time_type",
        },
        {
          stateShow: "长期",
          state: "chang_qi:get_time_type",
        },
      ],
      downListF: [
        //使用时间类型
        {
          stateShow: "绝对时间",
          state: "jue_dui:valid_type",
        },
        {
          stateShow: "相对时间",
          state: "xiang_dui:valid_type",
        },
      ],
      downListG: [
        //优惠券状态
        // = = :coupon_status= :coupon_status=
        {
          stateShow: "待发布",
          state: "dai_fa_bu:coupon_status",
        },
        {
          stateShow: "已发布",
          state: "yi_fa_bu:coupon_status",
        },
        {
          stateShow: "已下架",
          state: "yi_xia_jia:coupon_status",
        },
        {
          stateShow: "已过期",
          state: "yi_guo_qi:coupon_status",
        },
      ],

      isShowDialog2: false,
      dialogTitle2: "新建优惠券",
      diaWidth2: "910px",
      dialogForm2: {
        number: "",
        couponGetConditionId: "",
        couponName: "",
        couponStatus: "dai_fa_bu:coupon_status",
        couponType: "",
        discount: "",
        discountAmount: "",
        fullCondition: 0.01,
        getEndTime: "",
        getMethod: "",
        getStartTime: "",
        getTimeType: "",
        invalidTime: "",
        lengthDay: "",
        remark: "",
        subAmount: "",
        totalCount: "",
        getCount: "",
        remainingCount: "",
        useOrientation: "",
        validTimeType: "jue_dui:valid_type",
      },
      rulesForm2: {
        validTimeType: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        useOrientation: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        getTimeType: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        getMethod: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        couponType: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        couponStatus: [
          { required: true, message: "请选择", trigger: ["blur", "change"] },
        ],
        totalCount: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
        couponName: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },
      isShowDialog3: false,
      dialogForm3: {
        userIds: [],
        userId: "",
        couponId: "",
      },
      //=======================导出相关 开始 =======================
      dialogForm_dc: {
        pageStart: 1,
        pageTotal: "",
        fileName: "提货指令列表", //导出时默认文件名
        apiName: "/v1/common/query/runPageReport", //导出的数据列表接口名(动态列表都是这个)
        EpType: "bdd", //dd==等待下载完成,bdd==不等待完成
        apiType: "POST", //导出当前列表的请求方式
        isJson: 1, // 导出当前列表的请求头是否json
      },
      //=======================导出相关 结束 =======================
      pageGroupName: "DynamicListCouponManagement", //  页面标识
      reportFiledsNumber: "admin_coupon_list", // 动态 条件、表头
      // command_list admin_waybill_posting_list

      currentItemArr: [],
      dialogType: "",
      showTimers:false,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    // 上架优惠券
    upCouponBtns(row) {
      let list = this.currentItemArr || [];
      if (row) {
        list = [row];
      }
      if (list.length == 0) {
        this.$message.error("请选择需要上架的优惠券");
        return false;
      }
      this.$confirm("是否上架所选优惠券?", "系统提示:")
        .then((v) => {
          let ids = [];
          list.forEach((item) => {
            ids.push(item.couponId);
          });
          let param = {
            couponIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.upCoupon(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");

            let that = this;
            setTimeout(() => {
              that.queryBtn_ok();
            }, 500);
          });
        })
        .catch(() => {});
    },
    // 下架优惠券
    downCouponBtns(row) {
      let list = this.currentItemArr || [];
      if (row) {
        list = [row];
      }
      if (list.length == 0) {
        this.$message.error("请选择需要下架的优惠券");
        return false;
      }
      this.$confirm("是否下架所选优惠券?", "系统提示:")
        .then((v) => {
          let ids = [];
          list.forEach((item) => {
            ids.push(item.couponId);
          });
          let param = {
            couponIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.downCoupon(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");

            let that = this;
            setTimeout(() => {
              that.queryBtn_ok();
            }, 500);
          });
        })
        .catch(() => {});
    },
    // 指定发放
    sendCouponBtns(row) {
      let list = this.currentItemArr || [];
      if (row) {
        list = [row];
      }
      if (list.length == 0) {
        this.$message.error("请选择需要发放的优惠券");
        return false;
      }
      let ids = [];
      list.forEach((item) => {
        ids.push(item.couponId);
      });
      this.dialogForm3.couponId = ids[0];
      this.getUserData();
      this.isShowDialog3 = true;
    },
    // 删除优惠券
    delCouponBtns(row) {
      let list = this.currentItemArr || [];
      if (row) {
        list = [row];
      }
      if (list.length == 0) {
        this.$message.error("请选择需要删除的优惠券");
        return false;
      }
      this.$confirm("是否删除所选优惠券?", "系统提示:")
        .then((v) => {
          let ids = [];
          list.forEach((item) => {
            ids.push(item.couponId);
          });
          let param = {
            couponIds: ids,
          };
          param.sign = this.Tools.getSign(param);
          this.$API.delCoupon(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");

            let that = this;
            setTimeout(() => {
              that.queryBtn_ok();
            }, 500);
          });
        })
        .catch(() => {});
    },

    // 表格选择事件
    handleSelectionChange(e) {
      this.currentItemArr = e || [];
    },

    // 不能输入负数
    minNum(val) {
      return val < 0 ? 0 : val;
    },
    inputNumbeA(e) {
      let val = (e || 0) * 1;
      let num = this.minNum(val);
      this.dialogForm2.discountAmount = num;
    },
    inputNumbeB(e) {
      let val = (e || 0) * 1;
      let num = this.minNum(val);
      this.dialogForm2.fullCondition = num;
    },
    inputNumbeC(e) {
      let val = (e || 0) * 1;
      let num = this.minNum(val);
      this.dialogForm2.subAmount = num;
    },
    inputNumbeD(e) {
      let val = (e || 0) * 1;
      let num = this.minNum(val);
      this.dialogForm2.discount = num;
    },

    // 选择优惠券类型
    changeCouponType(e) {
      console.log(e);
      if (e === "xian_jin:coupon_type") {
        this.dialogForm2.fullCondition = 0.01;
        this.dialogForm2.subAmount = "";
        this.dialogForm2.discount = "";
      }
      if (e === "man_jian:coupon_type") {
        this.dialogForm2.fullCondition = 0.01;
        this.dialogForm2.discountAmount = "";
        this.dialogForm2.discount = "";
      }
      if (e === "man_zhe:coupon_type") {
        this.dialogForm2.fullCondition = 0.01;
        this.dialogForm2.discountAmount = "";
        this.dialogForm2.subAmount = "";
      }
    },
    // 选择使用时间类型
    changeValidTimeType(e) {
      console.log(e);
      if (e === "jue_dui:valid_type") {
        this.dialogForm2.lengthDay = "";
      }
      if (e === "xiang_dui:valid_type") {
        this.dialogForm2.invalidTime = "";
      }
    },
    // 选择领取时间类型
    changeGetTimeType(e) {
      console.log(e);
      if (e === "chang_qi:get_time_type") {
        this.dialogForm2.getStartTime = "";
        this.dialogForm2.getEndTime = "";
      }
    },

    // 修改优惠券
    async ModifyBtns(row) {
      console.log(row);
      this.getDown_list_coupon_type(true, "DF");
      let arrs = [
        {
          conditonKey: "couponId",
          conditonVal: row.couponId,
        },
      ];

      await this.$comAPI
        .runPageReportDetails("admin_coupon_detail", arrs)
        .then((res) => {
          if (res.length > 0) {
            this.dialogForm2 = res[0];

            this.dialogForm2.couponGetConditionId = this.dialogForm2
              .couponGetConditionId
              ? this.dialogForm2.couponGetConditionId * 1
              : "";
            let obj = {
              conditionName: this.dialogForm2.getConditionName || "",
              id: this.dialogForm2.couponGetConditionId
                ? this.dialogForm2.couponGetConditionId * 1
                : "",
            };
            this.downListC = [obj];
          }
        });
      this.dialogType = "edit";
      this.isShowDialog2 = true;
    },

    AddBtns() {
      this.getDown_list_coupon_type(true, "DF");
      this.dialogType = "add";
      this.isShowDialog2 = true;
    },

    // 获取优惠券类型
    getDown_list_coupon_type(e, type) {
      if (!e) {
        return false;
      }
      this.$comAPI.runPageReportDetails("down_list_coupon_type").then((res) => {
        this.downListB = res || [];

        if (type == "DF" && this.downListB.length > 0) {
          this.dialogForm2.couponType = this.downListB[0].state;
        }
        console.log(res);
      });
    },
    // 领取条件
    getDown_list_coupon_condition(e) {
      if (!e) {
        return false;
      }
      this.$comAPI
        .runPageReportDetails("down_list_coupon_condition")
        .then((res) => {
          this.downListC = res || [];
          console.log(res);
        });
    },

    //     admin_coupon_list	管理端-优惠券列表
    // down_list_coupon_type	优惠券类型下拉列表
    // down_list_coupon_status	优惠券状态下拉列表
    // admin_coupon_detail	管理端-优惠券详情

    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDia2() {
      this.isShowDialog2 = false;

      this.dialogForm2 = {
        couponGetConditionId: "",
        couponName: "",
        couponStatus: "dai_fa_bu:coupon_status",
        couponType: "",
        discount: "",
        discountAmount: "",
        fullCondition: 0.01,
        getEndTime: "",
        getMethod: "",
        getStartTime: "",
        getTimeType: "",
        invalidTime: "",
        lengthDay: "",
        remark: "",
        subAmount: "",
        totalCount: "",
        getCount: "",
        remainingCount: "",
        useOrientation: "",
        validTimeType: "jue_dui:valid_type",
      };
    },

    handleCloseDia3() {
      this.isShowDialog3 = false;
      this.dialogForm3 = {
        userIds: [],
        userId: "",
        couponId: [],
      };
    },

    // 获取数据
    getUserData(val) {
      console.log("val", val);
      let formData = {
        pageStart: 1,
        pageTotal: 50,
        employeeName: val || "", //员工姓名
        listType: "member:list_type", //all:list_type=全部; admin:list_type=管理员; member:list_type=会员账号
      };

      this.$API.adminUserList(formData).then((res) => {
        console.log("会员账号", res);
        this.userList = res.data.result?.data || [];
      });
    },

    changeUserList(e) {
      console.log("用户id", e);
    },

    // 指定发放
    diaConfirmBtn3() {
      if (!this.dialogForm3.userId) {
        this.$message.error("请选择用户账号");
        return false;
      }

      let param = {
        couponId: this.dialogForm3.couponId,
        userIds: [this.dialogForm3.userId],
      };
      param.sign = this.Tools.getSign(param);
      this.$API
        .sendCoupon(param)
        .then((res) => {
          this.$message.success(res.data.message || "操作成功");

          let that = this;
          setTimeout(() => {
            that.handleCloseDia3();
            that.queryBtn_ok();
          }, 500);
        })
        .catch(() => {});
    },

    //  弹窗确认保存事件
    diaConfirmBtn2(formName) {
      let datas = JSON.parse(JSON.stringify(this.dialogForm2));
      let param = {
        couponGetConditionId: datas.couponGetConditionId,
        couponName: datas.couponName,
        couponStatus: datas.couponStatus,
        couponType: datas.couponType,
        discount: datas.discount,
        discountAmount: datas.discountAmount,
        fullCondition: datas.fullCondition || 0.01,
        getEndTime: datas.getEndTime,
        getMethod: datas.getMethod,
        getStartTime: datas.getStartTime,
        getTimeType: datas.getTimeType,
        invalidTime: datas.invalidTime,
        lengthDay: datas.lengthDay,
        remark: datas.remark,
        subAmount: datas.subAmount,
        totalCount: datas.totalCount || 0,
        getCount: datas.getCount || 0,
        remainingCount: datas.remainingCount || 0,
        useOrientation: datas.useOrientation,
        validTimeType: datas.validTimeType,
      };

      console.log("param", param);
      if (this.dialogType == "edit") {
        // 修改
        param.couponId = datas.couponId || "";
        param.sign = this.Tools.getSign(param);
        this.$API.updateCoupon(param).then((res) => {
          this.$message.success(res.data.message || "新建成功");

          let that = this;
          setTimeout(() => {
            that.handleCloseDia2();
            that.queryBtn_ok();
          }, 800);
        });
      } else {
        // 新增
        param.sign = this.Tools.getSign(param);
        this.$API.addCoupon(param).then((res) => {
          this.$message.success(res.data.message || "新建成功");

          let that = this;
          setTimeout(() => {
            that.handleCloseDia2();
            that.queryBtn_ok();
          }, 800);
        });
      }
    },

    changeGetMethod(e) {
      console.log(e);
    },
  },
};
</script>
  <style scoped lang="scss">
::v-deep .el-date-editor.el-input {
  width: auto !important;
}

.classA {
  display: flex;
}

.glo_dialogForm .diaForm .dialogFormItems {
  height: 30px !important;
  line-height: 30px !important;
  overflow: hidden;
}

.couponBox {
  width: 100%;
  margin-bottom: 20px;
  height: 34px;

  .couponitmeInput {
    width: 100px;
    border: 1px solid #cccccc;
    margin: 0 6px;

    ::v-deep .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
  }

  .couponItemA {
    display: flex;
    align-items: center;
  }
}

.displays {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.rows {
  display: flex;
  width: 100%;
}

.multipleSelect {
  ::v-deep .el-select {
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
}
</style>
  